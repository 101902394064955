import React from "react"
import PropTypes from "prop-types"

const Flickr = ({ color }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 504.4 504.4"
      fill={color}
      height="512"
      width="512"
      role="img"
      aria-label="flickr"
    >
      <g>
        <g>
          <path
            d="M377.6,0.2H126.4C56.8,0.2,0,57,0,126.6v251.6c0,69.2,56.8,126,126.4,126H378c69.6,0,126.4-56.8,126.4-126.4V126.6
				C504,57,447.2,0.2,377.6,0.2z M158.8,325.4c-41.2,0-74.4-32.8-74.4-73.2s33.2-73.2,74.4-73.2c41.2,0,74.4,32.8,74.4,73.2
				C233.2,292.6,199.6,325.4,158.8,325.4z M345.2,325.4c-41.2,0-74.4-32.8-74.4-73.2S304,179,345.2,179c41.2,0,74.4,32.8,74.4,73.2
				C419.6,292.6,386.4,325.4,345.2,325.4z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

Flickr.propTypes = {
  color: PropTypes.string,
}

Flickr.defaultProps = {
  color: "#000000",
}

export default Flickr
